export default [
    { name: 'NodeJS' },
    { name: 'TypeScript' },
    { name: 'Jest' },
    { name: 'PHP' },
    { name: 'PHPUnit' },
    { name: 'Laravel' },
    { name: 'MySQL' },
    { name: 'MongoDB' },
    { name: 'Kafka' },
    { name: 'Redis' },
];