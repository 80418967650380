export default [
    {
        name: 'PHP',
    },
    {
        name: 'JavaScript',
    },
    {
        name: 'TypeScript',
    },
    {
        name: 'Swift',
    },
    {
        name: 'Python',
    },
    {
        name: 'C',
    },
    {
        name: 'C++',
    },
    {
        name: 'C#',
    }
];